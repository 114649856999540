import dayjs, { type Dayjs } from 'dayjs'
import firebase from 'firebase'
import { isEmpty } from 'lodash'
import * as FieldValue from 'constants/FieldValue'
import Model from 'models/model'
import type { InterestActionType, InterestedThemeType } from 'types'

class Matching extends Model {
  id?: string
  aceId?: string
  aceCompanyId?: string
  jokerId?: string
  operatorId?: string
  likeId?: string
  recruitmentId?: string
  lastMessage?: string
  lastSenderId?: string
  aceUnreadCount?: number
  jokerUnreadCount?: number
  isDoneVideoChat?: boolean
  isSentFirstSchedule?: boolean
  selectedInterestedAction?: InterestActionType
  selectedInterestedThemes?: InterestedThemeType[]
  matchedAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
  lastMessagedAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date

  static COLLECTION_NAME = 'matchings'

  constructor(init?: Partial<Matching>) {
    super()
    const defaultValues = {
      aceId: '',
      aceCompanyId: '',
      jokerId: '',
      isDoneVideoChat: false,
      isSentFirstSchedule: false,
      matchedAt: new Date(),
    }
    Object.assign(this, defaultValues)
    Object.assign(this, init)
  }

  get lastMessagedAtDayjs(): Dayjs | null {
    if (!this.lastMessagedAt) {
      return null
    }
    let lastMessagedAtDayjs: Dayjs | null = null
    if (this.lastMessagedAt instanceof firebase.firestore.Timestamp) {
      lastMessagedAtDayjs = dayjs(this.lastMessagedAt.toDate())
    } else if (this.lastMessagedAt instanceof Date) {
      lastMessagedAtDayjs = dayjs(this.lastMessagedAt)
    }
    return lastMessagedAtDayjs
  }
  get isNotOperated() {
    return (this.aceUnreadCount ?? 0) > 0
  }

  get selectedInterestedActionName() {
    if (isEmpty(this.selectedInterestedAction)) {
      return ''
    }
    const targetItem = FieldValue.INTEREST_ACTIONS.find(
      (item) => item.value === this.selectedInterestedAction,
    )
    return (targetItem && targetItem.name) || ''
  }

  get matchedAtString(): string {
    if (!this.matchedAt) {
      return ''
    }
    let matchedAtDayjs: Dayjs | null = null
    if (this.matchedAt instanceof firebase.firestore.Timestamp) {
      matchedAtDayjs = dayjs(this.matchedAt.toDate())
    } else if (this.matchedAt instanceof Date) {
      matchedAtDayjs = dayjs(this.matchedAt)
    }
    if (!matchedAtDayjs) {
      return ''
    }
    return matchedAtDayjs.isValid()
      ? matchedAtDayjs.format('YYYY/MM/DD HH:mm')
      : ''
  }

  get lastMessagedAtString(): string {
    if (!this.lastMessagedAtDayjs) {
      return ''
    }
    return this.lastMessagedAtDayjs.isValid()
      ? this.lastMessagedAtDayjs.format('YYYY/MM/DD HH:mm')
      : ''
  }

  get lastMessagedAtStringForMatchingList() {
    if (!this.lastMessagedAtDayjs) return ' - '
    const currentDayjs = dayjs()
    if (
      currentDayjs.year() === this.lastMessagedAtDayjs.year() &&
      currentDayjs.month() === this.lastMessagedAtDayjs.month() &&
      currentDayjs.date() === this.lastMessagedAtDayjs.date()
    ) {
      return this.lastMessagedAtDayjs.isValid()
        ? this.lastMessagedAtDayjs.format('HH:mm')
        : ' - '
    }
    return this.lastMessagedAtDayjs.isValid()
      ? this.lastMessagedAtDayjs.format('MM.DD HH:mm')
      : ' - '
  }
}

export default Matching
