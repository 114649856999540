import { isEmpty } from 'lodash'
import Repository from 'repositories/Repository'
import Matching from 'models/Matching'

class MatchingRepository extends Repository {
  constructor() {
    super()

    this.collection = this.mainFirestore.collection('matchings')
  }

  findById(matchingId) {
    return new Promise((resolve, reject) => {
      const companyId = localStorage.getItem('cid')
      if (isEmpty(companyId)) {
        reject(new Error('No company id'))
        return
      }

      this.collection
        .doc(matchingId)
        .get({ source: 'server' })
        .then((doc) => {
          if (!doc.exists) {
            resolve(null)
            return
          }
          const matching = new Matching({ id: doc.id, ...doc.data() })
          resolve(matching)
          return
        })
        .catch((error) => {
          // 存在しないマッチングの場合, permission-denied が返ってくる
          // TODO: バックエンド側でマッチングは論理削除にして
          // エラーが出ないようにするか, firestore.rules を変えたほうがいいかも...
          if (error.code === 'permission-denied') {
            resolve(null)
            return
          }
          reject(error)
          return
        })
    })
  }

  findByCompanyId(companyId) {
    const result = []
    return this.collection
      .where('aceCompanyId', '==', companyId)
      .orderBy('matchedAt', 'desc')
      .get({ source: 'server' })
      .then((docs) => {
        docs.forEach((doc) => {
          const matching = new Matching({ id: doc.id, ...doc.data() })
          result.push(matching)
        })
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  select(matchingIds) {
    const matchings = []
    const tasks = []

    if (!matchingIds || matchingIds.length === 0) {
      return Promise.resolve([])
    }

    matchingIds.forEach((matchingId) => {
      if (isEmpty(matchingId)) {
        return
      }
      const task = new Promise((resolve, reject) => {
        this.collection
          .doc(matchingId)
          .get({ source: 'server' })
          .then((doc) => {
            resolve(doc)
          })
          .catch((error) => {
            if (error.code === 'permission-denied') {
              resolve(null)
              return
            }
            reject(error)
          })
      })
      tasks.push(task)
    })

    return new Promise((resolve, reject) => {
      return Promise.all(tasks)
        .then((results) => {
          results.forEach((doc) => {
            if (!doc) {
              return
            }
            const matchingId = doc.id
            const matching = new Matching({ id: matchingId, ...doc.data() })
            matchings.push(matching)
          })
          resolve(matchings)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }
}

const matchingRepository = new MatchingRepository()

Object.freeze(matchingRepository)

export default matchingRepository
