import { type FC, useEffect, useState } from 'react'
import Image from 'next/image'
import type { NextImageProps } from 'components/common/NextImage'
import { NextStyledImage } from 'components/common/NextImage'

type Props = NextImageProps & {
  fallbackSrc: string
}

export const NextImageWithFallback: FC<Props> = ({
  src: defaultSrc,
  fallbackSrc,
  disableAnimation,
  ...restProps
}) => {
  const [loaded, setLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setIsError(false)
  }, [defaultSrc])

  if (disableAnimation) {
    return (
      <Image
        {...restProps}
        src={isError ? fallbackSrc : defaultSrc}
        alt={restProps.alt}
        onError={() => setIsError(true)}
      />
    )
  }

  return (
    <NextStyledImage
      data-loaded={loaded ? 'done' : ''}
      onLoadingComplete={() => setLoaded(true)}
      {...restProps}
      src={isError ? fallbackSrc : defaultSrc}
      onError={() => setIsError(true)}
    />
  )
}

NextImageWithFallback.displayName = 'NextImageWithFallback'
